import classNames from 'classnames';

import { useSession } from '../../hooks/use-session';

const FacilityVerificationBadge = ({
    className = 'ms-1',
    facilityId,
    verifiedFacilities,
    showIfNotVerified,
}: {
    className?: string;
    facilityId: string;
    verifiedFacilities?: string[];
    showIfNotVerified?: boolean;
}) => {
    const session = useSession();

    const facilities =
        verifiedFacilities || session.currentUser?.verifiedFacilities || [];

    if (facilities.includes(facilityId)) {
        return (
            <span className={classNames('badge bg-success', className)}>
                Verified
            </span>
        );
    }

    if (showIfNotVerified) {
        return (
            <span className={classNames('badge bg-danger', className)}>
                Not Verified
            </span>
        );
    }

    return null;
};

export default FacilityVerificationBadge;
