import { createContext } from 'react';

export interface IActionNeededContext {
    requests: number;
    missingTimeSubmissions: number;
    isLoading: boolean;
    reload: () => void;
}

const initialContext: IActionNeededContext = {
    isLoading: true,
    requests: 0,
    missingTimeSubmissions: 0,
    reload: () => {},
};

export const ActionNeededContext = createContext(initialContext);
