export const formatPhoneNumber = (phoneNumber?: string | null) => {
    if (phoneNumber) {
        const l = phoneNumber.length;
        const prefix = phoneNumber.slice(0, l - 10);
        const areaCode = phoneNumber.slice(l - 10, l - 7);
        const firstThree = phoneNumber.slice(l - 7, l - 4);
        const lastFour = phoneNumber.slice(l - 4);
        return `${prefix} (${areaCode}) ${firstThree}-${lastFour}`.trim();
    }

    return '';
};
