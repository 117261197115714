import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useError } from '@clh/ui';

import { Spinner } from '../../spinner';
import Nav from '../nav';
import NoOrphanIcon from '../no-wrap';

import { Pagination } from './pagination';
import {
    FetchApi,
    Column,
    PaginatedResponse,
    TableState,
    ApiResult,
    VirtualColumn,
} from './types';

export function isVirtualColumn<F extends FetchApi>(
    column: Column<F>
): column is VirtualColumn<F> {
    return (column as VirtualColumn<F>).virtual === true;
}

interface ColumnHeaderProps<F extends FetchApi> {
    column: Column<F>;
    setParams: (params: { sort?: string }) => void;
    params: { sort?: string };
}

const ColumnHeader = <F extends FetchApi>({
    column,
    setParams,
    params,
}: ColumnHeaderProps<F>) => {
    const title =
        typeof column.title == 'string'
            ? column.title
            : column.column.toString();

    if (isVirtualColumn(column) || !column.sort) {
        return (
            <th className={classNames('user-select-none', column.className)}>
                {title}
            </th>
        );
    }

    const [sortOn, dir] = params.sort
        ? params.sort.split(':')
        : [undefined, 'desc'];

    const isActive = sortOn === column.column;

    const sortIconClass =
        dir === 'desc' || !isActive ? 'bi bi-sort-down' : 'bi bi-sort-up';

    const sort = () => {
        const newDir = dir !== 'desc' || !isActive ? 'desc' : 'asc';

        setParams({ sort: `${column.column.toString()}:${newDir}` });
    };

    return (
        <th className={classNames('user-select-none', column.className)}>
            <a onClick={() => sort()} role="button">
                <NoOrphanIcon
                    icon={
                        <i
                            className={classNames(sortIconClass, {
                                'opacity-25': !isActive,
                            })}
                        ></i>
                    }
                >
                    {title}
                </NoOrphanIcon>
            </a>
        </th>
    );
};

interface ApiPaginatedTableProps<
    F extends FetchApi,
    P extends Parameters<F>[0],
> {
    fetch: F;
    /**
     * Array of columns to display in the table.
     * Any result record key not added as a column will not be displayed.
     */
    columns: Column<F>[] | ((tab: string | undefined) => Column<F>[]);
    /**
     * Params that will always be passed to the fetching function.
     * Set `count: true` here to use page number based pagination.
     */
    params: P;
    /**
     * Tabs to display at top of table. Requires a query or request parameter as discriminating field.
     */
    tabs?: {
        param: keyof Parameters<F>[0];
        tabs: {
            label: ReactNode;
            value: string;
        }[];
    };
    header?:
        | ReactNode
        | ((
              fetchAndSetResponse: () => void,
              response?: PaginatedResponse
          ) => ReactNode);
    /**
     * Element to show when no results are found.
     */
    emptyState?: ReactNode | ((tab?: string) => ReactNode);

    /**
     * Function to execute when determining whether to highlight a given row
     *
     * @returns Highlighting condition
     */
    highlightRow?: (row: ApiResult<F>) => boolean;
}

/**
 * React component for handling paginated API responses.
 *
 * Functionality:
 * - Server-side pagination with arrows or page selector.
 * - Server-side sorting.
 * - Server-side filtering via tabs.
 * - Full rendering control of each column.
 *
 * Designed to be used in conjuction with API routes using `PaginatedResponse` and
 * `Sortable` decorators. All request / response parameters are handled automatically
 * when used in this configuration. User is responsible for the visual layout and column
 * rendering of the table, as well as backend database query implementation.
 *
 * @param props {ApiPaginatedTableProps}
 * @returns Server-side paginated/sorted/filtered React component.
 */
export default function ApiPaginatedTable<
    F extends FetchApi,
    P extends Parameters<F>[0],
>(props: ApiPaginatedTableProps<F, P>) {
    const [params, _setParams] = useState<P>(props.params);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<PaginatedResponse>();
    const [error, setError] = useError();
    const [query] = useSearchParams();
    const tabOnLoad = props.tabs
        ? query.get(props.tabs.param.toString())
        : undefined;
    const [selectedTab, setSelectedTab] = useState<P[keyof P] | undefined>(
        props.tabs ? tabOnLoad ?? props.tabs.tabs[0].value : undefined
    );

    const setParams = (p: Parameters<F>[0], merge: boolean = true) => {
        if (!merge) {
            return _setParams({ ...props.params, ...p });
        }

        _setParams({
            ...props.params,
            ...params,
            ...p,
        });
    };

    const selectTab = (tab: keyof Parameters<F>[0]) => {
        if (!props.tabs) {
            return;
        }
        setSelectedTab(tab);
    };

    const fetchAndSetResponse = () => {
        const fetchParams =
            selectedTab && props.tabs
                ? { ...params, [props.tabs.param]: selectedTab }
                : { ...params };

        props
            .fetch(fetchParams)
            .then((data) => setResponse(data))
            .catch((err) => {
                setError(err);
                throw err;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        fetchAndSetResponse();
    }, [JSON.stringify(params), selectedTab]);

    useEffect(() => {
        _setParams((p) => ({
            ...p,
            ...props.params,
        }));
    }, [props.params]);

    const state = error
        ? TableState.Error
        : loading
        ? TableState.Loading
        : response?.result?.length
        ? TableState.Ready
        : TableState.NoData;

    const columns =
        typeof props.columns === 'function'
            ? props.columns(selectedTab)
            : props.columns;

    return (
        <>
            {props.header &&
                (typeof props.header === 'function'
                    ? props.header(fetchAndSetResponse, response)
                    : props.header)}
            {props.tabs && (
                <Nav type="tabs">
                    {props.tabs.tabs.map((tab) => (
                        <Nav.Link
                            fadeInactiveTabs
                            key={`tab-${tab.value}`}
                            onClick={() => selectTab(tab.value)}
                            uri={`${
                                window.location.pathname
                            }?status=${encodeURIComponent(tab.value)}`}
                            isSelected={selectedTab === tab.value}
                            title={tab.label}
                        />
                    ))}
                </Nav>
            )}
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <ColumnHeader
                                    column={column}
                                    setParams={setParams}
                                    params={params}
                                    key={`header-${column.column.toString()}`}
                                />
                            ))}
                        </tr>
                    </thead>
                    {state === TableState.Loading ? (
                        <tbody className="align-middle">
                            <tr>
                                <td colSpan={columns.length}>
                                    <Spinner />
                                </td>
                            </tr>
                        </tbody>
                    ) : state === TableState.Error ? (
                        <tbody className="align-middle">
                            <tr>
                                <td colSpan={columns.length}>{error}</td>
                            </tr>
                        </tbody>
                    ) : state === TableState.NoData ? (
                        <tbody className="align-middle">
                            <tr>
                                <td
                                    colSpan={columns.length}
                                    className="border-bottom-0"
                                >
                                    {typeof props.emptyState === 'function' ? (
                                        props.emptyState(selectedTab)
                                    ) : props.emptyState ? (
                                        props.emptyState
                                    ) : (
                                        <div className="text-center">
                                            <i>(none)</i>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    ) : state === TableState.Ready ? (
                        <>
                            <tbody className="align-middle">
                                {response?.result.map(
                                    (row: ApiResult<F>, ix: number) => (
                                        <tr
                                            key={`row-${ix}`}
                                            className={classNames({
                                                'table-active':
                                                    props.highlightRow
                                                        ? props.highlightRow(
                                                              row
                                                          )
                                                        : false,
                                            })}
                                        >
                                            {columns.map((column) => (
                                                <td
                                                    key={`column-${column.column.toString()}`}
                                                    className={column.className}
                                                >
                                                    {column.render
                                                        ? isVirtualColumn(
                                                              column
                                                          )
                                                            ? column.render(
                                                                  row,
                                                                  selectedTab
                                                                      ? selectedTab
                                                                      : undefined
                                                              )
                                                            : column.render(
                                                                  row[
                                                                      column
                                                                          .column
                                                                  ],
                                                                  row,
                                                                  selectedTab
                                                                      ? selectedTab
                                                                      : undefined
                                                              )
                                                        : row[
                                                              column.column
                                                          ].toString()}
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th
                                        colSpan={columns.length}
                                        className="border-bottom-0"
                                    >
                                        <div className="d-flex justify-content-end">
                                            <Pagination
                                                {...response?.meta}
                                                currentPage={
                                                    response?.meta
                                                        .currentPage || 1
                                                }
                                                previousPage={
                                                    response?.meta
                                                        .previousPage || null
                                                }
                                                nextPage={
                                                    response?.meta.nextPage ||
                                                    null
                                                }
                                                setParams={setParams}
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </>
                    ) : null}
                </table>
            </div>
        </>
    );
}
