import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCurrentSearchParams } from '../../helpers';

export enum CalendarView {
    MONTH,
    WEEK,
}

export const useCalendarView = (): [
    CalendarView,
    (view: CalendarView) => void,
] => {
    const [view, setView] = useState<CalendarView>(CalendarView.MONTH);
    const [searchParams, setSearchParams] = useSearchParams();

    const calendarViewFromParams = searchParams.get('view');

    useEffect(() => {
        if (calendarViewFromParams) {
            setView(+calendarViewFromParams as unknown as CalendarView);
        }
    }, [calendarViewFromParams]);

    const setCalendarView = (view: CalendarView) => {
        setSearchParams((c) => ({
            ...getCurrentSearchParams(c),
            view: view.toString(),
        }));
    };

    return [view, setCalendarView];
};
