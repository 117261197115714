import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    level?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark'
        | 'link'
        | null;
    isOutline?: boolean;
    size?: 'sm' | 'lg';
}

const Button = ({
    children,
    className,
    isLoading,
    isOutline,
    level = 'primary',
    size,
    ...props
}: ButtonProps) => {
    return (
        <button
            className={classNames(
                'btn',
                level ? `btn-${isOutline ? 'outline-' : ''}${level}` : '',
                size ? `btn-${size}` : '',
                className
            )}
            {...props}
        >
            {isLoading && (
                <>
                    <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </>
            )}
            {children}
        </button>
    );
};

export default Button;
