import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const Nav = ({
    children,
    className,
    type = 'pills',
}: {
    children: ReactNode;
    className?: string;
    type?: 'pills' | 'tabs';
}) => {
    return (
        <ul
            className={classNames(
                `nav nav-${type}`,
                {
                    'my-3': !className?.includes('my-'),
                },
                className
            )}
        >
            {children}
        </ul>
    );
};

const NavLink = ({
    className,
    uri,
    title,
    isChecked,
    hasProblem,
    isSelected: isSelectedFromProps,
    isDisabled,
    isAnchor,
    onClick,
    fadeInactiveTabs,
    target,
    rel,
    ...props
}: {
    className?: string;
    uri: string;
    title: ReactNode;
    isAnchor?: boolean;
    isChecked?: boolean;
    hasProblem?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
    fadeInactiveTabs?: boolean;
    target?: string;
    rel?: string;
}) => {
    const LinkComponent = isDisabled ? 'div' : isAnchor ? 'a' : Link;

    const isSelected = isSelectedFromProps || location.pathname.includes(uri);
    const href =
        uri === location.pathname
            ? location.pathname + location.search + location.hash
            : uri;

    return (
        <li className="nav-item">
            <LinkComponent
                className={classNames(
                    'nav-link',
                    {
                        active: isSelected,
                        'opacity-75': fadeInactiveTabs && !isSelected,
                        disabled: isDisabled,
                        'text-muted': isDisabled,
                    },
                    className
                )}
                to={href}
                href={href}
                aria-current={isSelected ? 'page' : undefined}
                onClick={onClick}
                target={target}
                rel={rel}
                {...props}
            >
                {title}

                {hasProblem ? (
                    <i
                        className={classNames(
                            'bi ms-2 bi-exclamation-circle-fill',
                            {
                                'text-danger': !isSelected,
                            }
                        )}
                    ></i>
                ) : isChecked ? (
                    <i className={'bi ms-2 bi-check-circle-fill'}></i>
                ) : null}
            </LinkComponent>
        </li>
    );
};

Nav.Link = NavLink;

export default Nav;
