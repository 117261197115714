import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Roles } from '@clh/api-client';

import { Footer } from './footer';
import { Header } from './header';
import { Impersonate } from './impersonate';
import LogIn from './log-in';
import { LogOut } from './log-out';
import MyAvailability from './my-availability';
import MyHistory from './my-history';
import { newRelic } from './newRelic';
import { PostLogin } from './post-sign-in';
import { ProtectedRoute } from './protected-route';
import ScrollToTop from './scroll-to-top';
import ErrorBoundary from './shared-components/error-boundary';
import { Spinner } from './spinner';
import VersionPing from './version-ping';

const MyFacilities = lazy(
    () => import(/* webpackChunkName: "my-facilities" */ './my-facilities')
);

const BrowseOrientations = lazy(
    () =>
        import(
            /* webpackChunkName: "browse-orientations" */ './browse-orientations'
        )
);

const ViewOrientation = lazy(
    () =>
        import(
            /* webpackChunkName: "browse-orientations/view-details" */ './browse-orientations/view-details'
        )
);

const BrowseShifts = lazy(
    () => import(/* webpackChunkName: "browse-shifts" */ './browse-shifts')
);

const ViewShift = lazy(
    () =>
        import(
            /* webpackChunkName: "browse-shifts-view-details" */ './browse-shifts/view-details'
        )
);

const Facility = lazy(
    () => import(/* webpackChunkName: "facility" */ './facility')
);

const FacilityAdmin = lazy(
    () => import(/* webpackChunkName: "facility-admin" */ './facility-admin')
);

const Profile = lazy(
    () => import(/* webpackChunkName: "profile" */ './profile')
);

const ProfileList = lazy(
    () =>
        import(/* webpackChunkName: "profile-list" */ './profile/profile-list')
);

const ProfileWelcome = lazy(
    () =>
        import(
            /* webpackChunkName: "profile-welcome" */ './profile/profile-welcome'
        )
);

const MySchedule = lazy(
    () => import(/* webpackChunkName: "my-schedule" */ './my-schedule')
);

const NurseProfile = lazy(
    () => import(/* webpackChunkName: "nurse-profile" */ './nurse-profile')
);

const OrientationTimeWorked = lazy(
    () =>
        import(
            /* webpackChunkName: "orientation-time-worked" */ './orientation-time-worked'
        )
);

const RateFacility = lazy(
    () =>
        import(
            /* webpackChunkName: "rate-facility" */ './rate-shift/rate-facility'
        )
);

const ConfidentialityAgreement = lazy(
    () =>
        import(
            /* webpackChunkName: "confidentiality-agreement" */ './shared-components/confidentiality-agreement'
        )
);

const EmploymentContract = lazy(
    () =>
        import(
            /* webpackChunkName: "employment-contract" */ './shared-components/employment-contract'
        )
);

const ShiftTimeWorked = lazy(
    () =>
        import(
            /* webpackChunkName: "shift-time-worked" */ './shift-time-worked'
        )
);

export function App() {
    useEffect(() => {
        if (
            !/\.calllight\.health(:\d+)?|\.humla.(dev|com)(:\d+)?$/.test(
                window.location.origin
            )
        ) {
            newRelic.noticeError(
                new Error(
                    `App loaded from non humla.com address: ${window.location.origin}`
                )
            );
        }
    }, []);

    return (
        <ErrorBoundary
            errorName="App Fatal error"
            errorType="APP_FATAL_ERROR"
            errorOrigin="App.tsx"
        >
            <VersionPing />
            <div className="app" data-front-end-version={GIT_COMMIT}>
                <div className="min-height-90-vh">
                    <BrowserRouter>
                        <ScrollToTop />
                        {window.location.pathname.endsWith('/print') ? null : (
                            <Header />
                        )}
                        <Suspense fallback={<Spinner />}>
                            <Routes>
                                <Route path="/" element={<LogIn />} />
                                <Route
                                    path="/site/create-account"
                                    element={<LogIn authFlow="signup" />}
                                />
                                <Route
                                    path="/site/sign-in"
                                    element={<LogIn />}
                                />
                                <Route
                                    path="/site/sign-out"
                                    element={<LogOut />}
                                />
                                <Route
                                    path="/site/post-sign-in"
                                    element={<PostLogin />}
                                />
                                <Route
                                    path="/site/profile/offer-letter-agreement/offer-letter/print"
                                    element={
                                        <ProtectedRoute>
                                            <EmploymentContract
                                                showLoader
                                                printOnMount
                                                showSignedDate
                                            />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/profile/offer-letter-agreement/agreement/print"
                                    element={
                                        <ProtectedRoute>
                                            <ConfidentialityAgreement
                                                showLoader
                                                printOnMount
                                            />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/profile"
                                    element={
                                        <ProtectedRoute>
                                            <ProfileWelcome />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/profile/list"
                                    element={
                                        <ProtectedRoute>
                                            <ProfileList />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/profile/*"
                                    element={
                                        <ProtectedRoute>
                                            <Profile />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/site/impersonate/:profileId"
                                    element={
                                        <ProtectedRoute
                                            role={Roles.PlatformAdministrator}
                                        >
                                            <Impersonate />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/stop-impersonation"
                                    element={
                                        <ProtectedRoute>
                                            <Impersonate stop={true} />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/browse-shifts"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <BrowseShifts />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/browse-shifts/:shiftId"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <ViewShift />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/browse-orientations"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <BrowseOrientations />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/browse-orientations/:orientationId"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <ViewOrientation />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/my-schedule/my-availability"
                                    element={<MyAvailability />}
                                />
                                <Route
                                    path="/site/my-schedule/orientation/:orientationId"
                                    element={<ViewOrientation />}
                                />
                                <Route
                                    path="/site/my-schedule/orientation/:orientationId/time-worked"
                                    element={<OrientationTimeWorked />}
                                />
                                <Route
                                    path="/site/my-schedule/shift/:shiftId"
                                    element={<ViewShift />}
                                />
                                <Route
                                    path="/site/my-schedule/shift/:shiftId/time-worked"
                                    element={<ShiftTimeWorked />}
                                />
                                <Route
                                    path="/site/my-schedule/shift/:shiftId/rate"
                                    element={<RateFacility />}
                                />
                                <Route
                                    path="/site/my-schedule/*"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <MySchedule />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/my-facilities"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <MyFacilities />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/my-history"
                                    element={
                                        <ProtectedRoute role={Roles.Nurse}>
                                            <MyHistory />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/facility-admin/*"
                                    element={
                                        <ProtectedRoute
                                            role={Roles.FacilityAdministrator}
                                        >
                                            <FacilityAdmin />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/facility/*"
                                    element={
                                        <ProtectedRoute
                                            role={Roles.FacilityAdministrator}
                                        >
                                            <Facility />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/nurse/:nurseProfileId"
                                    element={
                                        <ProtectedRoute
                                            role={[Roles.PlatformAdministrator]}
                                        >
                                            <div className="container mt-3">
                                                <NurseProfile />
                                            </div>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/site/nurse/:nurseProfileId/export"
                                    element={
                                        <ProtectedRoute
                                            role={[
                                                Roles.FacilityAdministrator,
                                                Roles.PlatformAdministrator,
                                            ]}
                                        >
                                            <div className="m-3">
                                                <NurseProfile isExport />
                                            </div>
                                        </ProtectedRoute>
                                    }
                                />
                            </Routes>
                        </Suspense>
                    </BrowserRouter>
                </div>
                {window.location.pathname.endsWith('/print') ? null : (
                    <Footer />
                )}
            </div>
        </ErrorBoundary>
    );
}
