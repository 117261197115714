import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { ActionsNeededCountsDto, Roles } from '@clh/api-client';
import { useRoles } from '@clh/ui';

import { newRelic } from '../../newRelic';
import { useApiClient } from '../use-api-client';
import { useSession } from '../use-session';

import { ActionNeededContext } from './action-needed-context';

export const ActionNeededProvider: React.FC<PropsWithChildren> =
    function ActionNeededProvider(props: PropsWithChildren) {
        const api = useApiClient();
        const { currentUser } = useSession();
        const { roles } = useRoles();
        const [isLoading, setIsLoading] = useState(true);
        const [counts, setCounts] = useState<ActionsNeededCountsDto>({
            requestsCount: 0,
            missingTimeSubmissionsCount: 0,
        });

        const fetchActionNeededCounts = () => {
            api
                ?.nurseControllerGetActionsNeededCounts({
                    profileId: currentUser!.id,
                })
                .then(setCounts)
                .catch((e) => {
                    newRelic.noticeError(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        useEffect(() => {
            if (currentUser?.id && roles.includes(Roles.Nurse)) {
                fetchActionNeededCounts();
            }
        }, [currentUser?.id]);

        const contextValue = useMemo(() => {
            return {
                requests: counts.requestsCount,
                missingTimeSubmissions: counts.missingTimeSubmissionsCount,
                isLoading,
                reload: fetchActionNeededCounts,
            };
        }, [counts, isLoading]);

        return (
            <ActionNeededContext.Provider value={contextValue}>
                {props.children}
            </ActionNeededContext.Provider>
        );
    };
