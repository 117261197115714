import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Roles } from '@clh/api-client';
import { useAccessToken, useApiClient, useRoles } from '@clh/ui';

import { newRelic } from '../../newRelic';

import { ISessionContext, SessionContext } from './session-context';

export const SessionProvider: React.FC<PropsWithChildren> =
    function SessionProvider(props: PropsWithChildren) {
        const { accessToken } = useAccessToken();
        const client = useApiClient(accessToken);
        const { roles, isLoading: isLoadingRoles } = useRoles();

        const [session, setSession] = useState<Omit<ISessionContext, 'reload'>>(
            {
                isLoading: true,
                hasRegistered: false,
                currentUser: null,
            }
        );

        const reload = useCallback(async () => {
            const currentUserId = sessionStorage.getItem('currentUserId');

            if (!accessToken || isLoadingRoles) {
                return;
            }

            try {
                setSession((session) => ({
                    ...session,
                    isLoading: session.currentUser ? false : true,
                }));

                const currentUserPromise = currentUserId
                    ? client!.profileControllerGetProfile({
                          profileId: currentUserId,
                      })
                    : client!.profileControllerGetCurrentUserProfile();

                const nurseProfileCompletenessPromise = roles.includes(
                    Roles.Nurse
                )
                    ? currentUserId
                        ? client!.nurseControllerGetProfileCompleteness({
                              profileId: currentUserId,
                          })
                        : client!.nurseControllerGetCurrentUserProfileCompleteness()
                    : null;

                const avatarPromise = currentUserId
                    ? client!
                          .profileControllerGetAvatar({
                              profileId: currentUserId,
                          })
                          .catch(() => ({ avatar: null }))
                    : client!
                          .profileControllerGetCurrentUserAvatar()
                          .catch(() => ({ avatar: null }));

                const [currentUser, nurseProfileCompleteness, { avatar }] =
                    await Promise.all([
                        currentUserPromise,
                        nurseProfileCompletenessPromise,
                        avatarPromise,
                    ]);

                if (currentUser?.id) {
                    newRelic.setUserId(currentUser.id);
                }

                setSession((s) => ({
                    ...s,
                    isLoading: false,
                    hasRegistered: true,
                    currentUser: {
                        ...currentUser,
                        nurseProfileCompleteness,
                        avatar,
                    },
                }));
            } catch {
                setSession((s) => ({
                    ...s,
                    isLoading: false,
                    hasRegistered: false,
                    currentUser: null,
                }));
            }
        }, [accessToken, setSession, isLoadingRoles, roles]);

        useEffect(() => {
            void reload();
        }, [accessToken, reload, isLoadingRoles]);

        const contextValue = useMemo(() => {
            return {
                ...session,
                reload,
            };
        }, [session, reload]);

        return (
            <SessionContext.Provider value={contextValue}>
                {props.children}
            </SessionContext.Provider>
        );
    };
