const CalendarLegend = () => {
    return (
        <div className="px-3 mb-3 flex align-items-center">
            <span className="flex align-items-center me-4">
                <i className="bi-circle-fill text-secondary text-small me-1"></i>
                <span className="me-1">=</span>
                <span>Shift or Orientation exists</span>
            </span>
            <span className="flex align-items-center">
                <i className="bi-check-circle-fill text-success text-small align-center me-1"></i>
                <span className="me-1">=</span>
                <span>Available to work</span>
            </span>
        </div>
    );
};

export default CalendarLegend;
