import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCurrentSearchParams } from '../../helpers';
import { useCalendar } from '../../hooks/use-calendar';
import { CalendarView } from '../../hooks/use-calendar-view';
import Button from '../button';

// TODO: accessible day headers
export default function CalendarPicker({
    availableDates = new Set(),
    className,
    datesWithEvents = new Set(),
    isSmall,
    minDate,
    onSelect,
    onTodayClickOverride: onSelectToday,
    seedDate = new Date(),
    selectedDates = new Set(),
    view,
}: {
    availableDates?: Set<string>;
    className?: string;
    datesWithEvents?: Set<string>;
    isSmall?: boolean;
    minDate?: Date;
    onSelect: (date: Date) => void;
    onTodayClickOverride?: (date: Date) => void;
    seedDate?: Date;
    selectedDates?: Set<string>;
    view?: CalendarView;
}) {
    const { date, setDate, weeks } = useCalendar(seedDate, view);
    const [searchParams, setSearchParams] = useSearchParams();

    const calendarPickerDateFromParams = searchParams.get('calendarPickerDate');

    useEffect(() => {
        if (calendarPickerDateFromParams) {
            setDate(
                DateTime.fromFormat(calendarPickerDateFromParams, 'yyyy-MM-dd')
            );
        }
    }, [calendarPickerDateFromParams]);

    return (
        <div className={className}>
            <div className="d-flex justify-content-between align-items-center">
                <h2
                    className={classNames('fs-5 mb-0 fw-bold', {
                        'fs-5 px-1 px-sm-3 pb-3': !isSmall,
                        'fs-6 px-2': isSmall,
                    })}
                >
                    {date.monthLong} {date.year}
                </h2>
                <div className="d-flex">
                    {!isSmall && (
                        <Button
                            level={null}
                            className="px-2"
                            onClick={() => {
                                setSearchParams((c) => ({
                                    ...getCurrentSearchParams(c),
                                    calendarPickerDate:
                                        DateTime.now().toFormat('yyyy-MM-dd'),
                                }));
                                setDate(DateTime.now());
                                const onSelectFn = onSelectToday || onSelect;
                                onSelectFn(DateTime.now().toJSDate());
                            }}
                        >
                            Today
                        </Button>
                    )}
                    <Button
                        level={null}
                        className="px-2"
                        onClick={() => {
                            setSearchParams((c) => ({
                                ...getCurrentSearchParams(c),
                                calendarPickerDate: date
                                    .minus(
                                        view === CalendarView.WEEK
                                            ? { week: 1 }
                                            : { month: 1 }
                                    )
                                    .toFormat('yyyy-MM-dd'),
                            }));
                        }}
                    >
                        <i className="bi bi-chevron-left" />
                    </Button>
                    <Button
                        level={null}
                        className="px-2"
                        onClick={() => {
                            setSearchParams((c) => ({
                                ...getCurrentSearchParams(c),
                                calendarPickerDate: date
                                    .plus(
                                        view === CalendarView.WEEK
                                            ? { week: 1 }
                                            : { month: 1 }
                                    )
                                    .toFormat('yyyy-MM-dd'),
                            }));
                        }}
                    >
                        <i className="bi bi-chevron-right" />
                    </Button>
                </div>
            </div>
            <table
                className={classNames('full-width pb-2 text-center', {
                    'px-2': !isSmall,
                })}
            >
                <thead>
                    <tr>
                        {[
                            'SUN',
                            'MON',
                            'TUES',
                            'WED',
                            'THUR',
                            'FRI',
                            'SAT',
                        ].map((d) => (
                            <th key={d}>
                                <small
                                    title={
                                        {
                                            SUN: 'Sunday',
                                            MON: 'Monday',
                                            TUES: 'Tuesday',
                                            WED: 'Wednesday',
                                            THUR: 'Thursday',
                                            FRI: 'Friday',
                                            SAT: 'Saturday',
                                        }[d]
                                    }
                                >
                                    {isSmall ? d.slice(0, 1) : d}
                                </small>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {weeks.map((w, i) => (
                        <tr key={i}>
                            {w.map(
                                (
                                    { date, isToday, isSeedDate, isSameMonth },
                                    i
                                ) => {
                                    const isSelectedDate = selectedDates.has(
                                        date.toFormat('yyyy-MM-dd')
                                    );

                                    return (
                                        <td
                                            key={i}
                                            className="align-top"
                                            style={{
                                                height: isSmall ? 28 : 56,
                                            }}
                                        >
                                            <Button
                                                className="p-0 border-0"
                                                level={null}
                                                disabled={
                                                    minDate &&
                                                    date.toJSDate() < minDate
                                                }
                                                onClick={() =>
                                                    onSelect(date.toJSDate())
                                                }
                                            >
                                                <span
                                                    className={classNames(
                                                        'd-inline-flex justify-content-center align-items-center rounded-circle',
                                                        {
                                                            'text-bg-secondary':
                                                                isToday &&
                                                                !isSeedDate,
                                                            'text-bg-success':
                                                                isSelectedDate,
                                                            'text-bg-primary':
                                                                isSeedDate,
                                                            'text-secondary':
                                                                !isToday &&
                                                                !isSeedDate &&
                                                                !isSelectedDate &&
                                                                !isSameMonth,
                                                        }
                                                    )}
                                                    style={
                                                        isSmall
                                                            ? {
                                                                  width: 24,
                                                                  height: 24,
                                                              }
                                                            : {
                                                                  width: 28,
                                                                  height: 28,
                                                              }
                                                    }
                                                >
                                                    {date.day}
                                                </span>
                                            </Button>

                                            {!isSmall && (
                                                <div>
                                                    {availableDates.has(
                                                        date.toFormat(
                                                            'yyyy-MM-dd'
                                                        )
                                                    ) && (
                                                        <i
                                                            style={{
                                                                fontSize: 10,
                                                            }}
                                                            className={classNames(
                                                                'bi',
                                                                {
                                                                    'bi-check-circle-fill text-success fs-small':
                                                                        true,
                                                                    'me-1': datesWithEvents.has(
                                                                        date.toFormat(
                                                                            'yyyy-MM-dd'
                                                                        )
                                                                    ),
                                                                }
                                                            )}
                                                        ></i>
                                                    )}
                                                    {datesWithEvents.has(
                                                        date.toFormat(
                                                            'yyyy-MM-dd'
                                                        )
                                                    ) && (
                                                        <i
                                                            className={classNames(
                                                                'bi bi-circle-fill text-secondary m-auto'
                                                            )}
                                                            style={{
                                                                fontSize: 10,
                                                            }}
                                                        ></i>
                                                    )}
                                                </div>
                                            )}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
