import { isEmpty, pickBy } from 'lodash';
import get from 'lodash/get';
import { DateTime, Interval } from 'luxon';

import {
    ElectronicMedicalRecordSystem,
    OrientationSessionDto,
    ShiftDto,
    ShiftTime,
} from '@clh/api-client';
import {
    getEstimatedHoursToBeWorkedFromShiftLength,
    getEstimatedMinutesToBeWorkedFromShiftLength,
} from '@clh/util';

export const EmrDisplayNames = {
    [ElectronicMedicalRecordSystem.Allscripts]: 'Allscripts',
    [ElectronicMedicalRecordSystem.AthenaHealth]: 'Athena Health',
    [ElectronicMedicalRecordSystem.Cerner]: 'Cerner',
    [ElectronicMedicalRecordSystem.Epic]: 'Epic',
    [ElectronicMedicalRecordSystem.Mckesson]: 'McKesson',
    [ElectronicMedicalRecordSystem.Meditech]: 'Meditech',
    [ElectronicMedicalRecordSystem.Myavatar]: 'myAvatar',
    [ElectronicMedicalRecordSystem.Other]: 'Other',
};

const TimeOfDayDisplayNames = {
    DAYS: 'Days',
    EVES: 'Evenings',
    NIGHTS: 'Nights',
};

export const ShiftLengths = {
    [ShiftTime.Days07001100]: 4,
    [ShiftTime.Days11001500]: 4,
    [ShiftTime.Days07001530]: 8,
    [ShiftTime.Days07001930]: 12,
    [ShiftTime.Eves15001900]: 4,
    [ShiftTime.Eves19002300]: 4,
    [ShiftTime.Eves15002330]: 8,
    [ShiftTime.Nights23000300]: 4,
    [ShiftTime.Nights03000700]: 4,
    [ShiftTime.Nights23000730]: 8,
    [ShiftTime.Nights19000730]: 12,
    [ShiftTime.Custom]: 0, // N/A
};

export const ShiftDisplayOrder = [
    ShiftTime.Days07001100,
    ShiftTime.Days11001500,
    ShiftTime.Days07001530,
    ShiftTime.Days07001930,
    ShiftTime.Eves15001900,
    ShiftTime.Eves19002300,
    ShiftTime.Eves15002330,
    ShiftTime.Nights23000300,
    ShiftTime.Nights03000700,
    ShiftTime.Nights23000730,
    ShiftTime.Nights19000730,
    ShiftTime.Custom,
];

export const sortedShiftInfos = Object.values(ShiftTime)
    .filter((time) => time !== ShiftTime.Custom)
    .sort((a, b) => ShiftDisplayOrder.indexOf(a) - ShiftDisplayOrder.indexOf(b))
    .map((time) => getShiftInfo(time));

export function getShiftInfo(
    shiftTime: ShiftTime,
    startTime?: Date,
    endTime?: Date,
    timezone?: string | null
) {
    if (startTime && endTime) {
        const { minutes } = DateTime.fromJSDate(endTime).diff(
            DateTime.fromJSDate(startTime, { zone: timezone || undefined }),
            'minutes'
        );

        const minutesMinusPotentialMealBreak =
            getEstimatedMinutesToBeWorkedFromShiftLength(minutes);

        return {
            timeOfDay: 'Other',
            from: DateTime.fromJSDate(startTime, {
                zone: timezone || undefined,
            }).toFormat('HHmm'),
            to: DateTime.fromJSDate(endTime, {
                zone: timezone || undefined,
            }).toFormat('HHmm'),
            lengthInMinutes: minutesMinusPotentialMealBreak,
            shiftTime,
        };
    }

    const [timeOfDay, from, to] = shiftTime.split('_');

    return {
        timeOfDay:
            TimeOfDayDisplayNames[timeOfDay as 'DAYS' | 'EVES' | 'NIGHTS'],
        from,
        to,
        lengthInMinutes: ShiftLengths[shiftTime] * 60,
        shiftTime,
    };
}

export function roundToAtMostTwoDecimals(n: number) {
    return Number(`${Math.round((n + 'e2') as any)}e-2`);
}

export const getShiftSchedule = (shift: Omit<ShiftDto, 'nurseProfile'>) => {
    const { timezone } = shift.facility.address;

    const startDate = DateTime.fromJSDate(
        shift.startTime,
        timezone
            ? {
                  zone: timezone,
              }
            : undefined
    );

    const { from, to, lengthInMinutes } = getShiftInfo(
        shift.shiftTime,
        shift.startTime,
        shift.endTime,
        shift.facility.address.timezone
    );

    return `${startDate.toLocaleString({
        month: 'long',
        day: 'numeric',
    })}, ${from}-${to} ${startDate.toFormat(
        'ZZZZ'
    )} (${roundToAtMostTwoDecimals(lengthInMinutes / 60)}hrs)`;
};

export function getSortedData<Dto>(
    key: string,
    isAscending: boolean,
    data: Dto[]
): Array<Dto> {
    return data.sort((a, b) => {
        const firstObj = a;
        const secondObj = b;

        const firstValue = get(firstObj, key);
        const secondValue = get(secondObj, key);

        if (firstValue && secondValue) {
            if (firstValue > secondValue) {
                return isAscending ? 1 : -1;
            } else if (firstValue < secondValue) {
                return isAscending ? -1 : 1;
            }
        } else {
            if (firstValue && !secondValue) {
                return isAscending ? 1 : -1;
            } else if (!firstValue && secondValue) {
                return isAscending ? -1 : 1;
            }
        }

        return 0;
    });
}

export const getTotalOrientationSessionHours = (
    sessions: OrientationSessionDto[]
) => {
    const totalHours = sessions.reduce((acc, session) => {
        const start = DateTime.fromJSDate(session.startTime);
        const end = DateTime.fromJSDate(session.endTime);
        const i = Interval.fromDateTimes(start, end);

        const hours = i.length('hours');

        const hoursMinusPossibleMealBreak =
            getEstimatedHoursToBeWorkedFromShiftLength(hours);

        acc += hoursMinusPossibleMealBreak;

        return acc;
    }, 0);

    return totalHours;
};

export const openModal = (id: string) => {
    const button = document.createElement('button');
    button.dataset.bsToggle = 'modal';
    button.dataset.bsTarget = `#${id}`;
    button.style.display = 'none';
    document.body.appendChild(button);
    button.click();
    document.body.removeChild(button);
};

export const closeModals = () => {
    const closeButtons = document.querySelectorAll<HTMLElement>(
        '[data-bs-dismiss="modal"][aria-label="close"]'
    );

    closeButtons.forEach((closeButton) => {
        closeButton.click();
    });
};

export const openOffcanvas = (id: string) => {
    const button = document.createElement('button');
    button.dataset.bsToggle = 'offcanvas';
    button.dataset.bsTarget = `#${id}`;
    button.style.display = 'none';
    document.body.appendChild(button);
    button.click();
    document.body.removeChild(button);
};

export const closeOffcanvas = () => {
    const closeButtons = document.querySelectorAll<HTMLElement>(
        '[data-bs-dismiss="offcanvas"][aria-label="close"]'
    );

    closeButtons.forEach((closeButton) => {
        closeButton.click();
    });
};

export const getCurrentSearchParams = (searchParams: URLSearchParams) => {
    return Array.from(searchParams.keys()).reduce<{
        [key: string]: string | string[];
    }>((acc, key: string) => {
        acc[key] = searchParams.getAll(key);
        return acc;
    }, {});
};

export const deleteEmptyObjectsAndArrays = (obj: object) => {
    return pickBy(
        obj,
        (v) => ['string', 'number', 'boolean'].includes(typeof v) || !isEmpty(v)
    );
};
