import { useState } from 'react';

import {
    RequestedBy,
    RequestedOrientationDto,
    RequestedShiftDto,
    ShiftRequestStatus,
    ShiftStatus,
} from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { openModal } from '../helpers';
import { useActionNeeded } from '../hooks/use-action-needed';
import { useSession } from '../hooks/use-session';
import { getOrientationStartTime } from '../my-schedule/helpers';
import { newRelic } from '../newRelic';
import Button from '../shared-components/button';

import ActionNeededModal from './action-needed-modal';

const sort = (items: (RequestedShiftDto | RequestedOrientationDto)[]) => {
    return items.sort((a, b) => {
        const aStart =
            'orientationSessions' in a
                ? getOrientationStartTime(a)
                : a.startTime;
        const bStart =
            'orientationSessions' in b
                ? getOrientationStartTime(b)
                : b.startTime;

        return aStart < bStart ? -1 : 1;
    });
};

const ActionNeeded = () => {
    const {
        requests: requestsCount,
        missingTimeSubmissions: missingTimeSubmissionsCount,
        isLoading,
    } = useActionNeeded();
    const api = useApiClient();
    const { currentUser } = useSession();
    const [isRequestsLoading, setIsRequestsLoading] = useState(true);
    const [
        isMissingTimeSubmissionsLoading,
        setIsMissingTimeSubmissionsLoading,
    ] = useState(true);

    const [shiftRequests, setShiftRequests] = useState<RequestedShiftDto[]>([]);
    const [orientationRequests, setOrientationRequests] = useState<
        RequestedOrientationDto[]
    >([]);
    const [shiftsMissingTime, setShiftsMissingTime] = useState<
        RequestedShiftDto[]
    >([]);
    const [orientationsMissingTime, setOrientationsMissingTime] = useState<
        RequestedOrientationDto[]
    >([]);

    const fetchShiftsMissingTime = () => {
        api
            ?.nurseControllerListShifts({
                profileId: currentUser!.id,
                requestStatus: ShiftRequestStatus.Accepted,
                endTime: new Date(),
                hasTimeLogged: false,
                shiftStatus: ShiftStatus.Active,
                pageSize: 100,
            })
            .then((shifts) => setShiftsMissingTime(shifts.result))
            .catch((e) => {
                newRelic.noticeError(e);
            });
    };

    const fetchShiftRequestsFromFacilities = () => {
        api
            ?.nurseControllerListShifts({
                profileId: currentUser!.id,
                requestedBy: RequestedBy.Facility,
                requestStatus: ShiftRequestStatus.Requested,
                startTime: new Date(),
                shiftStatus: ShiftStatus.Active,
            })
            .then((shifts) => setShiftRequests(shifts.result))
            .catch((e) => {
                newRelic.noticeError(e);
            });
    };

    const fetchOrientationsMissingTime = () => {
        api
            ?.nurseControllerListOrientations({
                profileId: currentUser!.id,
                requestStatus: ShiftRequestStatus.Accepted,
                endTime: new Date(),
                isMissingTime: true,
            })
            .then((response) => setOrientationsMissingTime(response.result))
            .catch((e) => {
                newRelic.noticeError(e);
            });
    };

    const fetchOrientationRequestsFromFacilities = () => {
        api
            ?.nurseControllerListOrientations({
                profileId: currentUser!.id,
                requestedBy: RequestedBy.Facility,
                requestStatus: ShiftRequestStatus.Requested,
                startTime: new Date(),
            })
            .then((response) => setOrientationRequests(response.result))
            .catch((e) => {
                newRelic.noticeError(e);
            });
    };

    const onRequestsClick = async () => {
        openModal('requests-from-facilities');
        await Promise.all([
            fetchShiftRequestsFromFacilities(),
            fetchOrientationRequestsFromFacilities(),
        ]).finally(() => {
            setIsRequestsLoading(false);
        });
    };

    const onMissingTimeSubmissionClick = async () => {
        openModal('missing-timecards');
        await Promise.all([
            fetchOrientationsMissingTime(),
            fetchShiftsMissingTime(),
        ]).finally(() => {
            setIsMissingTimeSubmissionsLoading(false);
        });
    };

    if (isLoading) {
        return null;
    }

    const requests = sort([...shiftRequests, ...orientationRequests]);
    const missingTimeSubmissions = sort([
        ...shiftsMissingTime,
        ...orientationsMissingTime,
    ]);

    return (
        <>
            <div className="bg-light d-flex align-items-center justify-content-end p-3">
                <span className="me-3">Action Needed:</span>
                <Button
                    className="me-3 position-relative"
                    isOutline
                    level="dark"
                    size="sm"
                    onClick={() => void onRequestsClick()}
                >
                    Requests
                    {requestsCount ? (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning text-black">
                            {requestsCount}
                            <span className="visually-hidden">
                                request from facilities
                            </span>
                        </span>
                    ) : null}
                </Button>
                <Button
                    className="position-relative"
                    isOutline
                    level="dark"
                    size="sm"
                    onClick={() => void onMissingTimeSubmissionClick()}
                >
                    Timecards
                    {missingTimeSubmissionsCount ? (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-purple">
                            {missingTimeSubmissionsCount}
                            <span className="visually-hidden">
                                timecards not submitted
                            </span>
                        </span>
                    ) : null}
                </Button>
            </div>
            <ActionNeededModal
                emptyState={
                    <div className="text-center my-4">
                        You don't not have any requests at this time.
                    </div>
                }
                id="requests-from-facilities"
                isLoading={isRequestsLoading}
                level="warning"
                requests={requests}
                title={`${requestsCount} Request${
                    requestsCount === 1 ? '' : 's'
                } from Facilities`}
            />
            <ActionNeededModal
                emptyState={
                    <div className="text-center my-4">
                        You are up to date on your timecard submissions.
                    </div>
                }
                id="missing-timecards"
                isLoading={isMissingTimeSubmissionsLoading}
                level="purple"
                requests={missingTimeSubmissions}
                title={`${missingTimeSubmissionsCount} Timecard${
                    missingTimeSubmissionsCount === 1 ? '' : 's'
                } Incomplete`}
            />
        </>
    );
};

export default ActionNeeded;
