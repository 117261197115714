import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Roles } from '@clh/api-client';
import { useError, useRoles } from '@clh/ui';

import { useSession } from '../hooks/use-session';
import { newRelic } from '../newRelic';
import Alert from '../shared-components/alert';
import { Spinner } from '../spinner';

export default function PostLogIn() {
    const session = useSession();
    const navigate = useNavigate();
    const { roles, isLoading: isLoadingRoles } = useRoles();
    const [params] = useSearchParams();
    const [error, setError] = useError();

    useEffect(() => {
        const error = params.get('error');
        const errorDescription = params.get('error_description');
        if (error) {
            newRelic.noticeError(error + ': ' + errorDescription);
            setError(error + ': ' + errorDescription);
        }

        if (session.isLoading || isLoadingRoles) {
            return;
        }
        const pathname = sessionStorage.getItem('pathname');
        const search = sessionStorage.getItem('search');
        const hash = sessionStorage.getItem('hash');

        if (pathname && pathname !== '/' && pathname !== '/site/post-sign-in') {
            sessionStorage.removeItem('pathname');
            sessionStorage.removeItem('search');
            sessionStorage.removeItem('hash');
            navigate([pathname, search, hash].join(''));
        } else if (!roles?.length || roles.includes(Roles.Nurse)) {
            const completedSections = Object.values(
                session.currentUser?.nurseProfileCompleteness || {}
            );

            if (completedSections.length && completedSections.every(Boolean)) {
                navigate('/site/browse-shifts', {
                    replace: true,
                });
            } else {
                navigate('/site/profile', { replace: true });
            }
        } else if (roles.includes(Roles.FacilityAdministrator)) {
            if (session.currentUser) {
                navigate('/site/facility/shifts');
            } else {
                navigate('/site/facility-admin/profile');
            }
        } else {
            // should not hit but just in case
            navigate('/site/profile', { replace: true });
        }
    }, [session.isLoading, isLoadingRoles]);

    if (error) {
        return <Alert className="m-3" message={error} />;
    }

    return <Spinner />;
}
