import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { RequestedOrientationDto } from '@clh/api-client';
import {
    calculateTotalPay,
    calculateTotalStipend,
    toDollarAmount,
} from '@clh/util';

import { closeModals, getTotalOrientationSessionHours } from '../../helpers';

const OrientationPreview = ({
    orientation,
    withViewDetailsLink,
}: {
    orientation: RequestedOrientationDto;
    withViewDetailsLink?: boolean;
}) => {
    const timezone = orientation?.facility?.address?.timezone;
    const dateOpts = timezone ? { zone: timezone } : undefined;

    const stipend = orientation.isQualifiedForStipend
        ? calculateTotalStipend(orientation.stipends) *
          orientation.orientationSessions.length
        : 0;

    return (
        <div className="row">
            <div className="col-8 text-start text-wrap d-flex flex-column justify-content-between">
                <div>
                    <h2 className="h5 mb-1">{orientation.facility.name}</h2>
                    <div className="h5">
                        {orientation.facility.address.city},{' '}
                        {orientation.facility.address.state}
                    </div>
                </div>
                {orientation.specialty && (
                    <div className="h5">{orientation.specialty.name}</div>
                )}
                {orientation.orientationSessions.map((session, index) => {
                    return (
                        <div
                            className={classNames('fs-6 fw-normal', {
                                'mb-1':
                                    index !==
                                    orientation.orientationSessions.length - 1,
                            })}
                            key={session.id}
                        >
                            {`Day ${index + 1}: ${DateTime.fromJSDate(
                                session.startTime,
                                dateOpts
                            ).toFormat('MMM dd, HHmm')}-${DateTime.fromJSDate(
                                session.endTime,
                                dateOpts
                            ).toFormat('HHmm ZZZZ')}`}
                        </div>
                    );
                })}
            </div>
            <div className="col-4 col-4 text-end fw-normal d-flex flex-column justify-content-between">
                {orientation && (
                    <>
                        <div className="mb-2">
                            <div
                                className={classNames('h4', {
                                    'mb-0': withViewDetailsLink,
                                })}
                            >
                                {toDollarAmount(
                                    calculateTotalPay(
                                        orientation.payRate,
                                        getTotalOrientationSessionHours(
                                            orientation.orientationSessions
                                        ) * 60,
                                        stipend
                                    )
                                )}
                            </div>
                            <div
                                className={classNames('fs-6', {
                                    'mb-2': stipend > 0,
                                })}
                            >
                                {toDollarAmount(orientation.payRate)} / hr
                            </div>
                            {stipend > 0 && (
                                <div className="fs-6">
                                    Includes {toDollarAmount(stipend)} Stipend
                                </div>
                            )}
                            {withViewDetailsLink ? (
                                <div className="fs-6">
                                    Open seats: {orientation.openSeats}
                                </div>
                            ) : null}
                        </div>
                        {withViewDetailsLink ? (
                            <Link
                                className="btn btn-primary btn-sm"
                                to={`/site/my-schedule/orientation/${orientation.id}`}
                                onClick={closeModals}
                            >
                                View Details
                            </Link>
                        ) : (
                            <div className="fs-6">
                                Open seats: {orientation.openSeats}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default OrientationPreview;
