import { ReactNode } from 'react';

export interface PaginatedResponse {
    result: Record<string, any>[];
    meta: {
        currentPage: number;
        previousPage: number | null;
        nextPage: number | null;
        isFirstPage?: boolean;
        isLastPage?: boolean;
        pageCount?: number;
        totalCount?: number;
        startCursor?: string;
        endCursor?: string;
    };
}

export type FetchApi = (...args: any[]) => Promise<PaginatedResponse>;

export type ApiResult<F extends FetchApi> = Awaited<
    ReturnType<F>
>['result'][number];

type SortableRequest<R, C> = R extends { sort?: unknown }
    ? C extends R['sort']
        ? boolean
        : never
    : never;

/**
 * A column keyed from a property of the record type returned.
 */
type ColumnConfig<F extends FetchApi, K extends keyof ApiResult<F>> = {
    column: K;
    /**
     * Title to appear in the column header.
     */
    title?: string;
    /**
     * Set to `true` to allow this column to be sorted on.
     */
    sort?: SortableRequest<Parameters<F>[0], K>;
    /**
     *
     * @param val The value of this column for the current row.
     * @param row The record row for the result type.
     * @param tab The currently selected tab. Does not get passed if tabs are not being used.
     * @returns Rendered column.
     */
    render?: (
        val: ApiResult<F>[K],
        row: ApiResult<F>,
        tab?: Parameters<F>[0][keyof Parameters<F>[0]]
    ) => ReactNode;
    className?: string;
};

/**
 * A column that is not bound to a physical table column.
 * Useful for columns that hold calls to action or summary data. Cannot be sorted on.
 */
export type VirtualColumn<F extends FetchApi> = {
    virtual: true;
    column: string;
    /**
     * Title to appear in the column header
     */
    title?: string;
    /**
     *
     * @param row The record row for this result type.
     * @param tab The currently selected tab. Does not get passed if tabs are not being used.
     * @returns React element to render.
     */
    render: (
        row: ApiResult<F>,
        tab?: Parameters<F>[0][keyof Parameters<F>[0]]
    ) => ReactNode;
    className?: string;
};

export type Column<F extends FetchApi> =
    | {
          [K in keyof ApiResult<F>]: ColumnConfig<F, K>;
      }[keyof ApiResult<F>]
    | VirtualColumn<F>;

export enum TableState {
    Loading,
    Ready,
    Error,
    NoData,
}
