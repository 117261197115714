import { DateTime } from 'luxon';

import {
    FacilityDto,
    NurseControllerListOrientationsRequest,
    OrientationSessionDto,
    RequestedOrientationDto,
    SpecialtyAbbrDto,
} from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { useSession } from '../hooks/use-session';
import ApiPaginatedTable from '../shared-components/api-paginated-table/api-paginated-table';
import { Spinner } from '../spinner';

export default function OrientationHistoryTable() {
    const apiClient = useApiClient();

    const session = useSession();

    if (!apiClient || !session.currentUser) {
        return <Spinner />;
    }

    return (
        <ApiPaginatedTable
            fetch={(
                requestParameters: NurseControllerListOrientationsRequest
            ) => apiClient.nurseControllerListOrientations(requestParameters)}
            header={(_, response) => (
                <h2 className="h4">
                    Orientations {response && `(${response.meta.totalCount})`}
                </h2>
            )}
            params={{
                pageSize: 10,
                count: true,
                profileId: session.currentUser.id,
                endTime: new Date(),
            }}
            columns={[
                {
                    column: 'facility',
                    title: 'Facility',
                    render: (facility: FacilityDto) => facility.name,
                },
                {
                    column: 'orientationSessions',
                    title: 'Details',
                    render: (
                        sessions: OrientationSessionDto[],
                        row: RequestedOrientationDto
                    ) => (
                        <>
                            {sessions.map((session, ix) => {
                                const start = DateTime.fromJSDate(
                                    session.startTime
                                );
                                const end = DateTime.fromJSDate(
                                    session.endTime
                                );
                                const isComplete =
                                    row.hasTimeLoggedForSessions.includes(
                                        session.id
                                    );
                                const didNotWork =
                                    row.didNotWorkSessions.includes(session.id);

                                return (
                                    <div key={session.id} className="mb-1">
                                        Day {ix + 1}:{' '}
                                        {start.toFormat('MM/dd/yyyy')}{' '}
                                        {start.toFormat('hhmm')}-
                                        {end.toFormat('hhmm')}{' '}
                                        {didNotWork ? (
                                            <i className="text-secondary">
                                                Did not work
                                            </i>
                                        ) : isComplete ? (
                                            <span className="badge text-bg-success">
                                                Complete
                                            </span>
                                        ) : (
                                            <span className="badge text-bg-purple">
                                                Timecard Incomplete
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    ),
                },
                {
                    column: 'specialty',
                    title: 'Specialty',
                    render: (specialty?: SpecialtyAbbrDto) =>
                        specialty ? specialty.name : null,
                },
                {
                    column: 'actions',
                    title: '',
                    virtual: true,
                    render: (row: RequestedOrientationDto) => (
                        <a href={`/site/my-schedule/orientation/${row.id}`}>
                            View Orientation
                        </a>
                    ),
                },
            ]}
        />
    );
}
