import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({
    children,
    isActive,
    to,
}: {
    children: ReactNode;
    isActive?: boolean;
    to?: string;
}) => (
    <li
        className={classNames('breadcrumb-item', {
            active: isActive,
        })}
        aria-current={isActive ? 'page' : undefined}
    >
        {to ? <Link to={to}>{children}</Link> : children}
    </li>
);

const Breadcrumbs = ({ children }: { children: ReactNode }) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">{children}</ol>
        </nav>
    );
};

export { Breadcrumb, Breadcrumbs };
