import Quill from 'quill';

export const getHtmlFromQuillDeltas = (mightBeDelta?: string) => {
    if (mightBeDelta) {
        if (mightBeDelta.startsWith('[') && mightBeDelta.endsWith(']')) {
            const el = document.createElement('div');
            const q = new Quill(el);

            q.setContents(JSON.parse(mightBeDelta));
            const html = q.root.innerHTML;
            el.remove();

            return html;
        }

        return mightBeDelta;
    }

    return '';
};
