import { ReactNode } from 'react';

const NoOrphanIcon = ({
    children,
    icon,
}: {
    children: string;
    icon: ReactNode;
}) => {
    const parts = children.split(' ');

    return (
        <>
            {parts.slice(0, parts.length - 1).join(' ')}{' '}
            <span className="text-nowrap">
                {parts[parts.length - 1]} {icon}
            </span>
        </>
    );
};

export default NoOrphanIcon;
