import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { Spinner } from '../spinner';

export const LogOut = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        sessionStorage.clear();
        void logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }, []);

    return <Spinner />;
};
