import { Breadcrumb, Breadcrumbs } from '../shared-components/breadcrumbs';

import OrientationHistoryTable from './orientation-history-table';
import ShiftHistoryTable from './shift-history-table';

const MyHistory = () => {
    return (
        <div className="container mt-3 mb-5">
            <Breadcrumbs>
                <Breadcrumb to="/site/profile">Home</Breadcrumb>
                <Breadcrumb isActive>My History</Breadcrumb>
            </Breadcrumbs>
            <h1 className="h3 mb-3">My History</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <OrientationHistoryTable />
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <ShiftHistoryTable />
                </div>
            </div>
        </div>
    );
};

export default MyHistory;
