import { useEffect, useState } from 'react';

const DESKTOP_MINUS_ONE = 992;

export default function useIsDesktop() {
    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > DESKTOP_MINUS_ONE
    );

    useEffect(() => {
        const fn = () => setIsDesktop(window.innerWidth > DESKTOP_MINUS_ONE);

        window.addEventListener('resize', fn);

        return () => {
            window.removeEventListener('resize', fn);
        };
    }, []);

    return isDesktop;
}
