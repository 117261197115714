import { FacilityShiftListItemDto, ShiftStatus } from '@clh/api-client';

export enum FacilityShiftUIStatus {
    OPEN,
    UPCOMING,
    CANCELED,
    COMPLETE,
}

export const getFacilityShiftUIStatus = (
    shift: Pick<
        FacilityShiftListItemDto,
        'startTime' | 'endTime' | 'nurseProfile' | 'status'
    >
) => {
    const now = new Date();

    if (shift.status === ShiftStatus.Canceled) {
        return FacilityShiftUIStatus.CANCELED;
    } else if (shift.endTime < now) {
        return FacilityShiftUIStatus.COMPLETE;
    } else if (shift.nurseProfile && shift.endTime > now) {
        return FacilityShiftUIStatus.UPCOMING;
    }

    return FacilityShiftUIStatus.OPEN;
};

export const getFacilityShiftUiStatusLevel = (
    status: FacilityShiftUIStatus
) => {
    return status === FacilityShiftUIStatus.OPEN
        ? 'warning'
        : status === FacilityShiftUIStatus.COMPLETE
        ? 'primary'
        : status === FacilityShiftUIStatus.CANCELED
        ? 'danger'
        : 'success'; // upcoming
};

export const getFacilityShiftUiStatusLabel = (
    status: FacilityShiftUIStatus
) => {
    return {
        [FacilityShiftUIStatus.OPEN]: 'Open / Unclaimed',
        [FacilityShiftUIStatus.COMPLETE]: 'Complete',
        [FacilityShiftUIStatus.CANCELED]: 'Canceled',
        [FacilityShiftUIStatus.UPCOMING]: 'Upcoming',
    }[status];
};
