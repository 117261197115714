import { DateTime, Interval } from 'luxon';

import { getEstimatedHoursToBeWorkedFromShiftLength } from '@clh/util';

export const getStartAndEndTimes = (
    date: string,
    startTime: string,
    endTime: string,
    timezone?: string | null
) => {
    const start = DateTime.fromISO(`${date}T${startTime}`, {
        zone: timezone || undefined,
    });
    let end = DateTime.fromISO(`${date}T${endTime}`, {
        zone: timezone || undefined,
    });

    if (end.valueOf() < start.valueOf()) {
        end = end.plus({ hours: 24 });
    }

    return { start, end };
};

export const measureDurationFromDateAndTimes = (
    date?: string,
    startTime?: string,
    endTime?: string
) => {
    if (!date || !startTime || !endTime) {
        return '';
    }

    const { start, end } = getStartAndEndTimes(date, startTime, endTime);

    const { hours } = Interval.fromDateTimes(start, end).toDuration('hours');

    const hoursMinusPotentialMealBreak =
        getEstimatedHoursToBeWorkedFromShiftLength(hours);

    return `${hoursMinusPotentialMealBreak.toLocaleString('US', {
        maximumFractionDigits: 2,
    })}hrs`;
};

export const measureDurationFromStartAndEndDates = (
    startDate: Date,
    endDate: Date
) => {
    const { hours } = Interval.fromDateTimes(
        DateTime.fromJSDate(startDate),
        DateTime.fromJSDate(endDate)
    ).toDuration('hours');

    const hoursMinusPotentialMealBreak =
        getEstimatedHoursToBeWorkedFromShiftLength(hours);

    return `${hoursMinusPotentialMealBreak.toLocaleString('US', {
        maximumFractionDigits: 2,
    })}hrs`;
};
