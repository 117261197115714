// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #000;
  opacity: 0.65;
}
.spinner-border.center-page {
  color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/spinner/spinner.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;AACJ;AAEA;EACI,WAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAAJ","sourcesContent":[".spinner-overlay {\n    z-index: 9;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    position: fixed;\n    background-color: #000;\n    opacity: 0.65;\n}\n\n.spinner-border.center-page {\n    color: #fff;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    width: 100px;\n    height: 100px;\n    margin-top: -50px;\n    margin-left: -50px;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
