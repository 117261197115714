import { PropsWithChildren, useMemo } from 'react';

import { useConfig } from '@clh/ui';

import {
    FeatureFlagsContext,
    FeatureFlagsContextValue,
} from './feature-flags-context';

type BillingFeesProviderProps = { facilityId?: string } & PropsWithChildren;

export const FeatureFlagsProvider: React.FC<BillingFeesProviderProps> =
    function FeatureFlagsProvider(props: BillingFeesProviderProps) {
        const config = useConfig();

        const contextValue = useMemo(() => {
            return (
                config.featureFlags?.reduce<FeatureFlagsContextValue>(
                    (acc, f) => {
                        acc[f as keyof FeatureFlagsContextValue] = true;
                        return acc;
                    },
                    {}
                ) || {}
            );
        }, [config.featureFlags]);

        return (
            <FeatureFlagsContext.Provider value={contextValue}>
                {props.children}
            </FeatureFlagsContext.Provider>
        );
    };
