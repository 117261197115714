import { useMemo } from 'react';

import { useAccessToken } from '@clh/ui';
import { apiMiddleware } from '@clh/util';
import { DefaultApi, makeWebappClient } from '@clh/web-client';

export interface ApiClient extends DefaultApi {
    isAuthenticated?: boolean;
    authenticationFailed?: boolean;
}

export function useWebappApiClient(
    accessTokenFromArgs?: string
): ApiClient | null {
    const {
        accessToken: accessTokenFromHook,
        error,
        clhSessionId,
    } = useAccessToken();
    const accessToken = accessTokenFromArgs || accessTokenFromHook;

    const apiClient = useMemo(() => {
        const apiClient: ApiClient = makeWebappClient({
            accessToken,
            middleware: [
                new apiMiddleware.UseSessionHeaderMiddleware(
                    clhSessionId,
                    GIT_COMMIT
                ),
            ],
        });

        apiClient.isAuthenticated = !!accessToken;
        apiClient.authenticationFailed = !!error;

        return apiClient;
    }, [accessToken, error]);

    return apiClient;
}
