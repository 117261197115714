import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ShiftDto } from '@clh/api-client';
import { toDollarAmount } from '@clh/util';

import { closeModals } from '../../helpers';
import FacilityVerificationBadge from '../facility-verification-badge';
import { getShiftData } from '../shift/shift';

const ShiftPreview = ({
    className,
    shift,
    withViewDetailsLink,
}: {
    className?: string;
    shift: ShiftDto;
    withViewDetailsLink?: boolean;
}) => {
    const shiftData = getShiftData({
        ...shift,
        stipends: shift.stipends
            ? shift.stipends.filter((s) => s.qualified)
            : [],
    });

    return (
        <div className={classNames('row', { className })}>
            <div className="col-8 text-start text-wrap">
                <h2 className="h5">{shiftData.title}</h2>
                <div className="mb-1 d-flex align-items-center">
                    <span className="fs-6 fw-normal">
                        {shiftData.facilityName}
                    </span>
                    <FacilityVerificationBadge facilityId={shift.facility.id} />
                </div>
                <div className="fs-6 mb-1 fw-normal">{shiftData.schedule}</div>
                <div className="fs-6 fw-normal">{shiftData.address}</div>
            </div>
            <div
                className={classNames('col-4 col-4 text-end fw-normal', {
                    'd-flex flex-column justify-content-between':
                        withViewDetailsLink,
                })}
            >
                <div>
                    <div className="h4">
                        {toDollarAmount(shiftData.costs.estimate.pay)}
                    </div>
                    <div
                        className={classNames('fs-6', {
                            'mb-2': shiftData.costs.estimate.stipend,
                        })}
                    >
                        {shiftData.payRate}
                    </div>
                    {shiftData.costs.estimate.stipend > 0 && (
                        <div className="fs-6">
                            Includes{' '}
                            {toDollarAmount(shiftData.costs.estimate.stipend)}{' '}
                            Stipend
                        </div>
                    )}
                </div>
                {withViewDetailsLink && (
                    <Link
                        className="btn btn-primary btn-sm"
                        to={`/site/my-schedule/shift/${shift.id}`}
                        onClick={closeModals}
                    >
                        View Details
                    </Link>
                )}
            </div>
        </div>
    );
};

export default ShiftPreview;
