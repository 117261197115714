import { useState } from 'react';

import { useSession } from '../hooks/use-session';

import './impersonate-button.less';

export const ImpersonateButton = () => {
    const currentUserId = sessionStorage.getItem('currentUserId');
    const { currentUser } = useSession();
    const [newUserId, setNewUserId] = useState<string>();

    if (!currentUserId || !currentUser) {
        return null;
    }

    const go = () => {
        if (!newUserId) {
            return;
        }
        window.location.href = `/site/impersonate/${newUserId}`;
    };

    return (
        <div
            className="alert alert-warning d-flex justify-content-between p-2"
            role="alert"
        >
            {newUserId === undefined ? (
                <div>
                    Viewing as {currentUser.firstName} {currentUser.lastName}
                    <button
                        className="ms-2 btn btn-sm text-bg-warning"
                        onClick={() => setNewUserId('')}
                    >
                        {currentUser.id.slice(0, 8)}
                        <div className="saasquatch-icon ms-1"></div>
                    </button>
                </div>
            ) : (
                <div className="row">
                    <div className="col-auto">
                        <label className="col-form-label">
                            Change to user ID:
                        </label>
                    </div>

                    <div className="col-auto">
                        <div className="input-group">
                            <input
                                type="text"
                                autoFocus={true}
                                onChange={(e) => setNewUserId(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && go()}
                                value={newUserId}
                                className="form-control-sm"
                                placeholder={currentUserId}
                            />
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                id="button-addon2"
                                onClick={() => go()}
                            >
                                <div className="saasquatch-icon"></div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <a
                href="/site/stop-impersonation"
                className="btn btn-link btn-sm link-secondary"
            >
                Exit Impersonation Mode
            </a>
        </div>
    );
};
