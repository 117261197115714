import classNames from 'classnames';
import { ReactNode } from 'react';

type AlertProps = {
    className?: string;
    showIcon?: ReactNode;
    message?: ReactNode;
    level?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark';
};

const Alert = ({
    showIcon,
    message,
    level = 'danger',
    className,
    ...props
}: AlertProps) => {
    if (Array.isArray(message) ? message.length : message) {
        return (
            <div
                className={classNames(
                    'alert',
                    `alert-${level}`,
                    {
                        'd-flex': showIcon,
                    },
                    className
                )}
                role="alert"
                {...props}
            >
                {showIcon && (
                    <div className="me-3 d-flex align-items-center">
                        <i className="bi bi-exclamation-triangle-fill"></i>
                    </div>
                )}
                {Array.isArray(message) && message.length > 1 ? (
                    <>
                        <div>Error:</div>
                        <ul className="mb-0">
                            {message.map((message) => (
                                <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </>
                ) : (
                    message
                )}
            </div>
        );
    }

    return null;
};

export default Alert;
