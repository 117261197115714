import classNames from 'classnames';
import { FormEvent, FormHTMLAttributes, ReactNode, useRef } from 'react';

import { Resolvable } from '@clh/util';

import Alert from '../shared-components/alert';

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
    errorMessage?: ReactNode;
    id?: string;
    successMessage?: ReactNode;
    wasValidated?: boolean;
    resetOnSubmit?: boolean;
    onBeginSubmit?: () => Resolvable;
    onFinishSubmit?: () => Resolvable;
    onSubmitError?: (e: any) => Resolvable;
    onSubmit?: (e: FormEvent<HTMLFormElement>) => Resolvable;
}

export default function Form({
    children,
    className,
    errorMessage,
    id,
    onBeginSubmit = () => {},
    onSubmitError = () => {},
    onFinishSubmit = () => {},
    onReset,
    onSubmit,
    resetOnSubmit,
    successMessage,
    wasValidated,
    ...props
}: FormProps) {
    const ref = useRef<HTMLFormElement>(null);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (typeof onSubmit === 'function') {
            onBeginSubmit();
            try {
                await onSubmit(e);

                if (resetOnSubmit) {
                    ref.current?.reset();
                }
            } catch (e) {
                onSubmitError(e);
            } finally {
                onFinishSubmit();
            }
        }
    };

    return (
        <form
            className={classNames(className, 'needs-validation', {
                'was-validated': wasValidated,
            })}
            noValidate
            onSubmit={handleSubmit as any}
            onReset={onReset}
            ref={ref}
            id={id}
            {...props}
        >
            <Alert message={errorMessage} level="danger" />
            <Alert message={successMessage} level="success" />
            {children}
        </form>
    );
}
