import classNames from 'classnames';
import { memo, ReactNode, useState } from 'react';

import { useError } from '@clh/ui';
import { Resolvable } from '@clh/util';

import Form, { FormProps } from '../../form-elements/form';
import Alert from '../alert';
import Button, { ButtonProps } from '../button';

interface IModal {
    body: ReactNode;
    cancelButtonProps?: ButtonProps;
    cancelText?: string;
    confirmElement?: ReactNode;
    modalSize?: 'sm' | 'lg' | 'xl';
    confirmButtonProps?: ButtonProps;
    confirmPendingText?: string;
    confirmText?: string;
    disabled?: boolean;
    formProps?: FormProps & { isDirty?: boolean };
    id: string;
    isForm?: boolean;
    isScrollable?: boolean;
    onCancel?: () => Resolvable;
    onConfirm?: () => Resolvable;
    showFooter?: boolean;
    title: ReactNode;
}

const Div = memo(function Div(props: any) {
    return <div {...props} />;
});

function Modal({
    disabled,
    onConfirm = () => {},
    onCancel = () => {},
    id,
    isForm,
    isScrollable,
    modalSize,
    formProps = {
        errorMessage: null,
        isDirty: false,
        wasValidated: false,
        onSubmit: () => {},
    },
    title,
    body,
    confirmElement = null,
    confirmText = 'Save',
    cancelText = 'Cancel',
    cancelButtonProps = {},
    confirmButtonProps = {},
    showFooter = true,
}: IModal) {
    const [isConfirming, setIsConfirming] = useState(false);
    const [error, setError] = useError();
    const handleConfirm = async () => {
        setIsConfirming(true);
        try {
            await onConfirm();
        } catch (e) {
            setError(e);
        }
        setIsConfirming(false);
    };

    const Wrapper = isForm ? Form : Div;
    return (
        <div
            className={classNames('modal fade')}
            id={id}
            tabIndex={-1}
            aria-labelledby={`${id}-title`}
        >
            <div
                className={classNames(
                    'modal-dialog',
                    {
                        'modal-dialog-scrollable': isScrollable,
                    },
                    modalSize ? `modal-${modalSize}` : ''
                )}
            >
                <Wrapper
                    className="modal-content"
                    {...(isForm
                        ? {
                              wasValidated: !!formProps.wasValidated,
                              onSubmit: formProps.onSubmit,
                              resetOnSubmit: formProps.resetOnSubmit,
                              style: {
                                  maxWidth: 'none',
                                  margin: 'initial',
                              },
                              onBeginSubmit: () => setIsConfirming(true),
                              onFinishSubmit: () => setIsConfirming(false),
                              onSubmitError: setError,
                          }
                        : {})}
                >
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={`${id}-title`}>
                            {title}
                        </h1>
                        <button
                            id={`${id}-close`}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Alert message={error || formProps.errorMessage} />
                        {body}
                    </div>
                    {showFooter && (
                        <div className="modal-footer">
                            <Button
                                level="secondary"
                                type={
                                    cancelText.toLocaleLowerCase() === 'reset'
                                        ? 'reset'
                                        : 'button'
                                }
                                data-bs-dismiss="modal"
                                onClick={onCancel}
                                {...cancelButtonProps}
                            >
                                {cancelText}
                            </Button>
                            {confirmElement ? (
                                confirmElement
                            ) : (
                                <Button
                                    disabled={
                                        disabled ||
                                        isConfirming ||
                                        (isForm ? !formProps.isDirty : false)
                                    }
                                    onClick={handleConfirm as any}
                                    isLoading={isConfirming}
                                    {...confirmButtonProps}
                                >
                                    {confirmText}
                                </Button>
                            )}
                        </div>
                    )}
                </Wrapper>
            </div>
        </div>
    );
}

export default Modal;
