// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/humla-color-logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.call-light-logo {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  height: 40px;
  width: 134px;
  display: inline-block;
  background-size: contain;
}
.call-light-header-right-circle {
  background-color: #FEBC11;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 20px;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  cursor: pointer;
}
.call-light-header-right-circle:hover {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/header/header.less"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,4BAAA;EACA,YAAA;EACA,YAAA;EACA,qBAAA;EACA,wBAAA;AACJ;AAEA;EACI,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAEI;EACI,WAAA;AAAR","sourcesContent":[".call-light-logo {\n    background-image: url('../images/humla-color-logo.png');\n    background-repeat: no-repeat;\n    height: 40px;\n    width: 134px;\n    display: inline-block;\n    background-size: contain;\n}\n\n.call-light-header-right-circle {\n    background-color: #FEBC11;\n    height: 40px;\n    width: 40px;\n    text-align: center;\n    border-radius: 20px;\n    font-size: 20px;\n    color: #fff;\n    line-height: 40px;\n    cursor: pointer;\n\n    &:hover {\n        color: #fff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
