// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/saasquatch-foot-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saasquatch-icon {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.saasquatch-icon::after {
  position: absolute;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-left: -6px;
  margin-top: -2px;
  content: "";
}
`, "",{"version":3,"sources":["webpack://./src/impersonate/impersonate-button.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;AACJ;AAEA;EACI,kBAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;AAAJ","sourcesContent":[".saasquatch-icon {\n    position: relative;\n    width: 16px;\n    height: 16px;\n    display: inline-block;\n}\n\n.saasquatch-icon::after {\n    position: absolute;\n    background-image: url('../images/saasquatch-foot-icon.png');\n    background-repeat: no-repeat;\n    background-size: contain;\n    width: 20px;\n    height: 20px;\n    margin-left: -6px;\n    margin-top: -2px;\n    content: \"\";\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
