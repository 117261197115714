import { useContext } from 'react';

import { ISessionContext, SessionContext } from './session-context';

export const useSession = (): ISessionContext => useContext(SessionContext);

export function getInitials(session: ISessionContext) {
    const personsInitials =
        [session.currentUser?.firstName, session.currentUser?.lastName]
            .map((it) => it?.charAt(0))
            .filter(Boolean)
            .join('') || '';

    return personsInitials;
}
