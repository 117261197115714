// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  text-align: center;
  padding: 20px;
  background-color: #c5c5c5;
  border-top: 1px solid #b1b1b1;
}
`, "",{"version":3,"sources":["webpack://./src/footer/footer.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,6BAAA;AACJ","sourcesContent":[".footer {\n    text-align: center;\n    padding: 20px;\n    background-color: #c5c5c5;\n    border-top: 1px solid #b1b1b1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
