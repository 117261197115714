import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { FacilityDto, Roles } from '@clh/api-client';
import { useRoles } from '@clh/ui';

import { newRelic } from '../../newRelic';
import { useApiClient } from '../use-api-client';
import { useSession } from '../use-session';

import { FacilityContext } from './facility-context';

export const FacilityProvider: React.FC<PropsWithChildren> =
    function SessionProvider(props: PropsWithChildren) {
        const apiClient = useApiClient();
        const [isLoading, setIsLoading] = useState(true);
        const { roles, isLoading: isLoadingRoles } = useRoles();
        const [facilities, setFacilities] = useState<FacilityDto[]>([]);
        const [facility, setFacility] = useState<{
            current: FacilityDto | null;
            last: FacilityDto | null;
        }>({
            current: null,
            last: null,
        });
        const session = useSession();

        const getLocalStorageId = () => {
            return `selectedFacility-${session.currentUser?.id.slice(-4)}`;
        };

        const setSelectedFacility = (facilityId: string) => {
            const facility = facilities.find((f) => f.id === facilityId);

            if (facility) {
                localStorage.setItem(getLocalStorageId(), facility.id);

                setFacility((f) => ({
                    current: facility,
                    last: f.current,
                }));
            }
        };

        useEffect(() => {
            if (
                session.currentUser?.id &&
                !isLoadingRoles &&
                roles.includes(Roles.FacilityAdministrator)
            ) {
                apiClient
                    ?.profileControllerGetMemberFacilities({
                        profileId: session.currentUser.id,
                    })
                    .then((facilities) => {
                        if (facilities?.length) {
                            setFacilities(facilities);

                            const selectedFacilityId = localStorage.getItem(
                                getLocalStorageId()
                            );

                            const selectedFacility = selectedFacilityId
                                ? facilities.find(
                                      (f) => f.id === selectedFacilityId
                                  )
                                : null;

                            if (selectedFacility) {
                                setFacility({
                                    current: selectedFacility,
                                    last: null,
                                });
                            } else {
                                localStorage.setItem(
                                    getLocalStorageId(),
                                    facilities[0].id
                                );

                                setFacility({
                                    current: facilities[0],
                                    last: null,
                                });
                            }
                        }
                    })
                    .catch((e) => {
                        newRelic.noticeError(e);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else if (
                session.currentUser?.id &&
                !isLoadingRoles &&
                !roles.includes(Roles.FacilityAdministrator)
            ) {
                setIsLoading(false);
            }
        }, [session.currentUser?.id, isLoadingRoles]);

        const contextValue = useMemo(() => {
            return {
                selectedFacility: facility?.current,
                lastSelectedFacility: facility?.last,
                setSelectedFacility,
                facilities,
                isLoading,
            };
        }, [facility, isLoading]);

        return (
            <FacilityContext.Provider value={contextValue}>
                {props.children}
            </FacilityContext.Provider>
        );
    };
