import {
    FacilityDto,
    SpecialtyAbbrDto,
    NurseControllerListShiftsRequest,
    RequestedShiftDto,
} from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { useSession } from '../hooks/use-session';
import ApiPaginatedTable from '../shared-components/api-paginated-table/api-paginated-table';
import { Spinner } from '../spinner';

export default function ShiftHistoryTable() {
    const apiClient = useApiClient();

    const session = useSession();

    if (!apiClient || !session.currentUser) {
        return <Spinner />;
    }

    return (
        <ApiPaginatedTable
            fetch={(requestParameters: NurseControllerListShiftsRequest) =>
                apiClient.nurseControllerListShifts(requestParameters)
            }
            header={(_, response) => (
                <h2 className="h4">
                    Shifts {response && `(${response.meta.totalCount})`}
                </h2>
            )}
            params={{
                pageSize: 10,
                count: true,
                profileId: session.currentUser.id,
                endTime: new Date(),
            }}
            columns={[
                {
                    column: 'facility',
                    title: 'Facility',
                    render: (facility: FacilityDto) => facility.name,
                },
                {
                    column: 'specialty',
                    title: 'Specialty',
                    render: (specialty: SpecialtyAbbrDto) =>
                        specialty ? specialty.name : null,
                },
                {
                    column: 'didNotWork',
                    title: '',
                    render: (didNotWork: boolean) =>
                        didNotWork && (
                            <i className="text-secondary">Did not work</i>
                        ),
                },
                {
                    column: 'startTime',
                    title: 'Date',
                    render: (startTime: Date) => startTime.toLocaleDateString(),
                },
                {
                    column: 'actions',
                    title: '',
                    virtual: true,
                    render: (row: RequestedShiftDto) => (
                        <a href={`/site/my-schedule/shift/${row.id}`}>
                            View Shift
                        </a>
                    ),
                },
            ]}
        />
    );
}
