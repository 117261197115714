import { createContext } from 'react';

export interface ICourierTokenContext {
    courierAuthToken: string | null;
    reloadCourierAuthToken: () => void;
}

const initialContext: ICourierTokenContext = {
    courierAuthToken: null,
    reloadCourierAuthToken: () => {},
};

export const CourierTokenContext = createContext(initialContext);
