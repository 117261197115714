import classNames from 'classnames';

import './spinner.less';

export function Spinner({
    className,
    isFullPage,
}: {
    className?: string;
    isFullPage?: boolean;
}) {
    if (isFullPage) {
        return (
            <div className={className} data-testid="spinner-full">
                <div className="spinner-overlay"></div>
                <div className="spinner-border center-page" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames(
                'mb-3 d-flex justify-content-center',
                className,
                {
                    'mt-5': !className?.includes('mt-'),
                }
            )}
            data-testid="spinner-inline"
        >
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}
