export const getAvatarStyles = (avatar?: string | null, moreStyles = {}) =>
    avatar
        ? {
              backgroundColor: 'white',
              backgroundImage: `url(${avatar})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              ...moreStyles,
          }
        : undefined;
