import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import { Roles } from '@clh/api-client';
import { useRoles } from '@clh/ui';

import LogIn from './log-in';
import { Spinner } from './spinner';

interface ProtectedRouteProps {
    requireAuthentication?: boolean;
    role?: Roles | Roles[];
    children: React.ReactNode;
}

export function ProtectedRoute({
    requireAuthentication = true,
    role,
    children,
}: ProtectedRouteProps) {
    const { isAuthenticated } = useAuth0();

    const { roles, isLoading } = useRoles();

    if (isLoading) {
        return <Spinner />;
    }

    if (requireAuthentication && !isAuthenticated) {
        return <LogIn redirectToCurrentPage />;
    }

    if (role !== undefined) {
        const required = Array.isArray(role) ? role : [role];

        if (
            required.length > 0 &&
            required.filter((req) => roles.includes(req)).length === 0
        ) {
            return <LogIn />;
        }
    }

    return <>{children}</>;
}
