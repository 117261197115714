import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

import { CalendarView } from '../use-calendar-view';

export const useCalendar = (
    seedDate = new Date(),
    view: CalendarView = CalendarView.MONTH
) => {
    const [date, setDate] = useState<DateTime>(DateTime.fromJSDate(seedDate));

    useEffect(() => {
        setDate(DateTime.fromJSDate(seedDate));
    }, [seedDate.toISOString().slice(0, 10)]);

    const weeks = useMemo(() => {
        const weeks = [];

        if (view === CalendarView.WEEK) {
            const startOfWeek = (
                date.weekday === 7 ? date.plus({ day: 1 }) : date
            ).startOf('week');
            const startDayOfWeek = startOfWeek.weekday;

            const week = [];

            for (let d = 0; d < 7; d++) {
                const date = startOfWeek.plus({
                    day: d - startDayOfWeek,
                });
                const isToday =
                    DateTime.now().toFormat('yyyy-MM-dd') ===
                    date.toFormat('yyyy-MM-dd');

                const isSeedDate =
                    DateTime.fromJSDate(seedDate).toFormat('yyyy-MM-dd') ===
                    date.toFormat('yyyy-MM-dd');

                const isSameMonth = date.hasSame(startOfWeek, 'week');
                week.push({
                    date,
                    isToday,
                    isSeedDate,
                    isSameMonth,
                });
            }

            weeks.push(week);
        } else {
            const startDayOfMonth = date.startOf('month').weekday;

            const weeksInMonth = Math.ceil(
                (date.daysInMonth + startDayOfMonth) / 7
            );
            const startOfMonth = date.startOf('month');

            // if week starts on a sunday, we do not need the negative calendar days
            for (let w = startDayOfMonth === 7 ? 1 : 0; w < weeksInMonth; w++) {
                const week = [];

                for (let d = 0; d < 7; d++) {
                    const date = startOfMonth.plus({
                        day: w * 7 + d - startDayOfMonth,
                    });
                    const isToday =
                        DateTime.now().toFormat('yyyy-MM-dd') ===
                        date.toFormat('yyyy-MM-dd');

                    const isSeedDate =
                        DateTime.fromJSDate(seedDate).toFormat('yyyy-MM-dd') ===
                        date.toFormat('yyyy-MM-dd');

                    const isSameMonth = date.hasSame(startOfMonth, 'month');
                    week.push({
                        date,
                        isToday,
                        isSeedDate,
                        isSameMonth,
                    });
                }

                weeks.push(week);
            }
        }

        return weeks;
    }, [date, seedDate, view]);

    return {
        weeks,
        date,
        setDate,
    };
};
