import { createContext } from 'react';

import { FacilityDto } from '@clh/api-client';

export interface IFacilityContext {
    isLoading: boolean;
    facilities: FacilityDto[];
    selectedFacility: FacilityDto | null;
    lastSelectedFacility: FacilityDto | null;
    setSelectedFacility: (facilityId: string) => void;
}

const initialContext: IFacilityContext = {
    isLoading: true,
    facilities: [],
    selectedFacility: null,
    lastSelectedFacility: null,
    setSelectedFacility: () => {},
};

export const FacilityContext = createContext(initialContext);
