import { ReactNode, useEffect, useState } from 'react';

import { newRelic } from '../../newRelic';
import { useWebappApiClient } from '../use-webapp-api-client';

import { CourierTokenContext } from './courier-auth-token-context';

export const CourierAuthTokenProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const webappApiClient = useWebappApiClient();
    const [token, setToken] = useState<string | null>(null);

    const fetchCourierToken = () => {
        webappApiClient
            ?.bootstrapControllerGetCourierAuthToken()
            .then(({ token }) => {
                if (token && typeof token === 'string') {
                    setToken(token);
                } else {
                    throw new Error(
                        `Unknown courier token format. Expected string but got ${typeof token}`
                    );
                }
            })
            .catch((err) => newRelic.noticeError(err));
    };

    useEffect(() => {
        if (webappApiClient?.isAuthenticated) {
            fetchCourierToken();
        }
    }, [webappApiClient?.isAuthenticated]);

    return (
        <CourierTokenContext.Provider
            value={{
                courierAuthToken: token,
                reloadCourierAuthToken: fetchCourierToken,
            }}
        >
            {children}
        </CourierTokenContext.Provider>
    );
};
