import classNames from 'classnames';
import { ReactNode } from 'react';

import { RequestedOrientationDto, RequestedShiftDto } from '@clh/api-client';

import Modal from '../shared-components/modal';
import OrientationPreview from '../shared-components/orientation-preview';
import ShiftPreview from '../shared-components/shift-preview';
import { Spinner } from '../spinner';

const ActionNeededModal = ({
    id,
    isLoading,
    requests,
    title,
    level,
    emptyState,
}: {
    id: string;
    isLoading?: boolean;
    requests: (RequestedShiftDto | RequestedOrientationDto)[];
    title: ReactNode;
    level: 'purple' | 'warning';
    emptyState: ReactNode;
}) => {
    return (
        <Modal
            id={id}
            title={
                <div className="d-flex align-items-center">
                    <span
                        className={`d-inline-block rounded-circle bg-${level} me-2`}
                        style={{
                            width: 16,
                            height: 16,
                        }}
                    ></span>
                    {title}
                </div>
            }
            body={
                isLoading ? (
                    <Spinner className="mt-3" />
                ) : requests.length ? (
                    requests.map((shiftOrOrientation, index) => (
                        <div
                            key={shiftOrOrientation.id}
                            className={classNames('px-2 py-4', {
                                'border-bottom': index !== requests.length - 1,
                                'pt-0': index === 0,
                                'pb-0': index === requests.length - 1,
                            })}
                        >
                            {'orientationSessions' in shiftOrOrientation ? (
                                <>
                                    <div className="fw-bold mb-2">
                                        ORIENTATION
                                    </div>
                                    <OrientationPreview
                                        orientation={shiftOrOrientation}
                                        withViewDetailsLink
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="fw-bold mb-2">SHIFT</div>
                                    <ShiftPreview
                                        shift={shiftOrOrientation}
                                        withViewDetailsLink
                                    />
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    emptyState
                )
            }
            showFooter={false}
        />
    );
};

export default ActionNeededModal;
