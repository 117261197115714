import {
    NurseShiftCancellationDto,
    OrientationDto,
    OrientationStatus,
    RequestedBy,
    RequestedOrientationDto,
    RequestedShiftDto,
    ShiftRequestStatus,
    ShiftStatus,
} from '@clh/api-client';

export enum ShiftUIStatus {
    CONFIRMED,
    CANCELED,
    COMPLETED,
    REQUESTED_BY_NURSE,
    REQUESTED_BY_FACILITY,
    MISSING_TIME,
}

export enum EventType {
    ORIENTATION,
    SHIFT,
}

export type Event = {
    id: string;
    title: string;
    start: Date;
    timezone?: string;
    end: Date;
    resource: (RequestedOrientationDto | RequestedShiftDto) & {
        uiStatus: ShiftUIStatus;
        type: EventType;
    };
};

export const getOrientationStartTime = (
    orientation: Pick<OrientationDto, 'orientationSessions'>
) => {
    return orientation.orientationSessions[0].startTime;
};

export const getOrientationEndTime = (
    orientation: Pick<OrientationDto, 'orientationSessions'>
) => {
    return orientation.orientationSessions[
        orientation.orientationSessions.length - 1
    ].endTime;
};

export const getOrientationMinutesWorked = (
    orientation: RequestedOrientationDto
) => {
    return orientation.hasTimeLogged;
};

export const getOrientationUIStatus = (
    orientation: RequestedOrientationDto
) => {
    const now = new Date();

    if (orientation.status === OrientationStatus.Canceled) {
        return ShiftUIStatus.CANCELED;
    } else if (
        orientation.requestStatus === ShiftRequestStatus.Requested &&
        orientation.requestedBy === RequestedBy.Facility
    ) {
        return ShiftUIStatus.REQUESTED_BY_FACILITY;
    } else if (
        orientation.requestStatus === ShiftRequestStatus.Requested &&
        orientation.requestedBy === RequestedBy.Nurse
    ) {
        return ShiftUIStatus.REQUESTED_BY_NURSE;
    } else if (
        orientation.requestStatus === ShiftRequestStatus.Accepted &&
        getOrientationEndTime(orientation) < now &&
        orientation.hasTimeLogged
    ) {
        return ShiftUIStatus.COMPLETED;
    } else if (
        orientation.requestStatus === ShiftRequestStatus.Accepted &&
        getOrientationEndTime(orientation) < now &&
        !orientation.hasTimeLogged
    ) {
        return ShiftUIStatus.MISSING_TIME;
    } else {
        return ShiftUIStatus.CONFIRMED;
    }
};

export const getUiStatusLabel = (shiftUiStatus: ShiftUIStatus) => {
    return {
        [ShiftUIStatus.CONFIRMED]: 'Confirmed',
        [ShiftUIStatus.COMPLETED]: 'Time Submitted',
        [ShiftUIStatus.CANCELED]: 'Canceled',
        [ShiftUIStatus.MISSING_TIME]: 'Timecard Incomplete',
        [ShiftUIStatus.REQUESTED_BY_NURSE]: 'Requested by You',
        [ShiftUIStatus.REQUESTED_BY_FACILITY]: 'Requested by Facility',
    }[shiftUiStatus];
};

export const getShiftUiStatusLevel = (uiStatus: ShiftUIStatus) => {
    return {
        [ShiftUIStatus.CONFIRMED]: 'success',
        [ShiftUIStatus.MISSING_TIME]: 'purple',
        [ShiftUIStatus.COMPLETED]: 'primary',
        [ShiftUIStatus.CANCELED]: 'danger',
        [ShiftUIStatus.REQUESTED_BY_NURSE]: 'orange',
        [ShiftUIStatus.REQUESTED_BY_FACILITY]: 'warning',
    }[uiStatus] as 'success' | 'purple' | 'primary' | 'danger' | 'warning';
};

export const getShiftUIStatus = (shift: {
    status: ShiftStatus;
    cancellations?: NurseShiftCancellationDto[] | null;
    requestStatus?: ShiftRequestStatus;
    requestedBy?: RequestedBy;
    startTime: Date;
    endTime: Date;
    minutesWorked: number | null;
    didNotWork: boolean;
}) => {
    const now = new Date();

    if (
        shift.status === ShiftStatus.Canceled ||
        shift.requestStatus === ShiftRequestStatus.Canceled ||
        (shift.cancellations?.length && shift.cancellations.length > 0)
    ) {
        return ShiftUIStatus.CANCELED;
    } else if (
        shift.requestStatus === ShiftRequestStatus.Requested &&
        shift.requestedBy === RequestedBy.Facility
    ) {
        return ShiftUIStatus.REQUESTED_BY_FACILITY;
    } else if (
        shift.requestStatus === ShiftRequestStatus.Requested &&
        shift.requestedBy === RequestedBy.Nurse
    ) {
        return ShiftUIStatus.REQUESTED_BY_NURSE;
    } else if (
        shift.requestStatus === ShiftRequestStatus.Accepted &&
        shift.startTime < now &&
        (typeof shift.minutesWorked === 'number' || shift.didNotWork)
    ) {
        return ShiftUIStatus.COMPLETED;
    } else if (
        shift.requestStatus === ShiftRequestStatus.Accepted &&
        shift.endTime < now &&
        !shift.minutesWorked
    ) {
        return ShiftUIStatus.MISSING_TIME;
    } else if (shift.requestStatus === ShiftRequestStatus.Accepted) {
        return ShiftUIStatus.CONFIRMED;
    }

    return null;
};

export const getShiftUiStatusLabel = (shiftUiStatus: ShiftUIStatus) => {
    return {
        [ShiftUIStatus.CONFIRMED]: 'Claimed',
        [ShiftUIStatus.COMPLETED]: 'Completed',
        [ShiftUIStatus.CANCELED]: 'Canceled',
        [ShiftUIStatus.MISSING_TIME]: 'Timecard Incomplete',
        [ShiftUIStatus.REQUESTED_BY_FACILITY]: 'Requested by Facility',
        [ShiftUIStatus.REQUESTED_BY_NURSE]: 'Requested by You',
    }[shiftUiStatus];
};
