export const newRelic =
    'NREUM' in window
        ? NREUM
        : {
              addPageAction: () => {},
              noticeError: () => {},
              setUserId: () => {},
              setApplicationVersion: () => {},
              setCustomAttribute: () => {},
          };
