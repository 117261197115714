import classNames from 'classnames';

import { FetchApi, PaginatedResponse } from './types';

const PageLink = (props: {
    onClick: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
}) => {
    return (
        <li
            role={!props.disabled ? 'button' : undefined}
            className={classNames('page-item', {
                disabled: props.disabled,
            })}
        >
            <a className="page-link" onClick={props.onClick}>
                {props.children}
            </a>
        </li>
    );
};

/**
 * Return sliding page numbers with left and right markers for ellipsis.
 *
 * Example: paginate(6) => [1, "left", 4, 5, 6, 7, 8, "right", 20]
 *
 * @param current Current page.
 * @param total Total number of pages.
 * @returns Array of page numbers with ellipsis if necessary.
 */
function paginate(current: number, total: number) {
    if (total === 1) {
        return [1];
    }

    const center = [
            current - 2,
            current - 1,
            current,
            current + 1,
            current + 2,
        ],
        includeThreeLeft = current === 5,
        includeThreeRight = current === total - 4,
        includeLeftDots = current > 5,
        includeRightDots = current < total - 4;

    const filteredCenter: Array<string | number> = center.filter(
        (p) => p > 1 && p < total
    );

    if (includeThreeLeft) {
        filteredCenter.unshift(2);
    }
    if (includeThreeRight) {
        filteredCenter.push(total - 1);
    }

    if (includeLeftDots) {
        filteredCenter.unshift('left');
    }
    if (includeRightDots) {
        filteredCenter.push('right');
    }

    return [1, ...filteredCenter, total];
}

type PaginationProps<F extends FetchApi> = PaginatedResponse['meta'] & {
    setParams: (p: Parameters<F>[0], merge?: boolean) => void;
};

export const PageNumbers = <F extends FetchApi>(props: PaginationProps<F>) => {
    const { pageCount, currentPage } = props;

    if (!pageCount || !currentPage) {
        return null;
    }

    const pages = paginate(currentPage, pageCount);

    return (
        <>
            {pages.map((page) =>
                page === 'left' || page === 'right' ? (
                    <li key={`ellipsis-${page}`} className="page-item disabled">
                        <a className="page-link">&hellip;</a>
                    </li>
                ) : (
                    <PageLink
                        key={`page-${page}`}
                        disabled={props.currentPage === page}
                        onClick={() =>
                            props.currentPage !== page &&
                            props.setParams({ page })
                        }
                    >
                        {page}
                    </PageLink>
                )
            )}
        </>
    );
};

/**
 * Numbered links only appear if { count: true } is passed to the paginated response route
 *
 * @param props PaginationProps
 * @returns ReactNode
 */
export function Pagination<F extends FetchApi>(props: PaginationProps<F>) {
    const { currentPage, isFirstPage, isLastPage, setParams } = props;

    return (
        <nav aria-label="pagination">
            <ul className="pagination">
                <PageLink
                    disabled={isFirstPage}
                    onClick={() =>
                        !isFirstPage && setParams({ page: currentPage - 1 })
                    }
                >
                    <span aria-hidden="true">
                        <i className="bi bi-caret-left-fill"></i>
                    </span>
                </PageLink>
                {props.pageCount && <PageNumbers {...props} />}
                <PageLink
                    disabled={props.isLastPage}
                    onClick={() =>
                        !isLastPage && setParams({ page: currentPage + 1 })
                    }
                >
                    <span aria-hidden="true">
                        <i className="bi bi-caret-right-fill"></i>
                    </span>
                </PageLink>
            </ul>
        </nav>
    );
}
