import { createContext } from 'react';

export interface FeatureFlagsContextValue {
    TB_SCREENING?: boolean;
    CUSTOM_SHIFT_TIMES?: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>(
    undefined as any
);
