import { Component, ReactNode } from 'react';

import { newRelic } from '../../newRelic';
import Alert from '../alert';

type ErrorBoundaryProps = {
    children: ReactNode;
    errorName: string;
    errorType: string;
    errorOrigin: string;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        newRelic.noticeError(error, { errorInfo });
        newRelic.noticeError({
            name: this.props.errorName,
            start: Date.now(),
            origin: this.props.errorOrigin,
            type: this.props.errorType,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Alert
                    data-testid="error-boundary"
                    className="m-4"
                    message="Sorry, an error has occurred."
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
