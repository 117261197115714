import { ImgHTMLAttributes, ReactNode, useEffect, useState } from 'react';

export type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
    facilityId?: string;
    onPhotoNotFound?: () => void;
    cacheKey?: number;
    fallback?: ReactNode;
    src?: string | null;
};

const Image = ({
    className = 'img-fluid',
    src,
    onPhotoNotFound = () => {},
    fallback = null,
    cacheKey,
    ...props
}: ImageProps) => {
    const [isError, setIsError] = useState(false);

    const onError = () => {
        setIsError(true);
        onPhotoNotFound();
    };

    useEffect(() => {
        setIsError(false);
    }, [cacheKey]);

    if (isError || !src) {
        return <>{fallback}</>;
    }

    const cacheBuster = cacheKey ? `?photoKey=${cacheKey}` : '';

    if (src) {
        return (
            <img
                {...props}
                crossOrigin="anonymous"
                src={src + cacheBuster}
                className={className}
                onError={onError}
            />
        );
    }

    return null;
};

export default Image;
