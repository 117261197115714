import { createContext } from 'react';

import { NurseProfileCompletenessDto, ProfileDto } from '@clh/api-client';

export interface ISessionContext {
    isLoading: boolean;
    currentUser:
        | (ProfileDto & {
              avatar: string | null;
              nurseProfileCompleteness?: NurseProfileCompletenessDto | null;
          })
        | null;
    hasRegistered: boolean;
    reload: () => Promise<void>;
}

const initialContext: ISessionContext = {
    isLoading: true,
    hasRegistered: false,
    currentUser: null,
    reload: async () => {},
};

export const SessionContext = createContext(initialContext);
