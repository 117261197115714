import './footer.less';

export function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer>
            <div className="footer">
                Humla Health &copy; {year} - All Rights Reserved
            </div>
        </footer>
    );
}
